<template>
	<div class="team-preview">
		<div class="view-wrapper">
			<v-container fluid>
				<v-row dense>
					<v-col cols="3">
						<v-card class="card_shadow p-5">
							<div class="team_member d-flex justify-content-between align-items-center">
								<div class="font-team grey--text">TOTAL EARNING</div>
								<div class="font-level-3-bold team-span">
									<span class="success--text"
										><v-icon color="success">mdi-arrow-top-right </v-icon>+16.24%</span
									>
								</div>
							</div>
							<div class="fw-500 price-section">
								<p>$559.25k</p>
							</div>
							<div class="fw-500 chip_part">
								<a href="#" style="text-decoration: underline">View net earning</a>
								<div class="icon_box success lighten-4">
									<v-icon color="success"> mdi-currency-usd </v-icon>
								</div>
							</div>
						</v-card>
					</v-col>
					<v-col cols="3">
						<v-card class="card_shadow p-5">
							<div class="team_member d-flex justify-content-between align-items-center">
								<div class="font-team grey--text">ORDERS</div>
								<div class="font-level-3-bold team-span">
									<span class="red--text">
										<v-icon color="red">mdi-arrow-bottom-left </v-icon>
										-3.57%</span
									>
								</div>
							</div>
							<div class="fw-500 price-section">
								<p>36,894</p>
							</div>
							<div class="fw-500 chip_part">
								<a href="#" style="text-decoration: underline">View net earning</a>
								<div class="icon_box blue lighten-4">
									<v-icon color="blue">mdi-application </v-icon>
								</div>
							</div>
						</v-card>
					</v-col>
					<v-col cols="3">
						<v-card class="card_shadow p-5">
							<div class="team_member d-flex justify-content-between align-items-center">
								<div class="font-team grey--text">CUSTOMERS</div>
								<div class="font-level-3-bold team-span">
									<span class="success--text"
										><v-icon color="success">mdi-arrow-top-right </v-icon>+29.08%</span
									>
								</div>
							</div>
							<div class="fw-500 price-section">
								<p>183.35M</p>
							</div>
							<div class="fw-500 chip_part">
								<a href="#" style="text-decoration: underline">See details</a>
								<div class="icon_box orange lighten-4">
									<v-icon color="orange"> mdi-account-circle </v-icon>
								</div>
							</div>
						</v-card>
					</v-col>
					<v-col cols="3">
						<v-card class="card_shadow p-5">
							<div class="team_member d-flex justify-content-between align-items-center">
								<div class="font-team grey--text">MY BALANCE</div>
								<div class="font-level-3-bold team-span">
									<span class="">+0.00%</span>
								</div>
							</div>
							<div class="fw-500 price-section">
								<p>$165.89k</p>
							</div>
							<div class="fw-500 chip_part">
								<a href="#" style="text-decoration: underline">Withdraw money</a>
								<div class="icon_box indigo lighten-4">
									<v-icon color="indigo">mdi-folder-plus-outline </v-icon>
								</div>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
export default {
	name: "Viewdetails",
	data: () => ({
		cards: [
			{
				title: "Favorite road trips",
				src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
				flex: 6,
			},
			{ title: "Best airlines", src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg", flex: 6 },
		],
	}),
};
</script>
